import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context'
import Swal from 'sweetalert2'

import { UnauthenticatedUserRoutes } from '../routes/unauthenticated-user-routes';

const UNAUTHENTICATED_PATHS =
  UnauthenticatedUserRoutes.map(route => `/${route.path}`.replace('//', '/'))

const authLink = setContext((_, { headers } ) => {
    return {
        headers: {
            ...headers,
            authorization: localStorage.getItem('token') || ''
        }
    }
})

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
    credentials: "include", // http-only cookie that carries the refresh token
})

/*
 * Middleware to Intercept GraphQL Errors
 * The UNAUTHENTICATED error from an API request on an Authenticated Route.
 * will cause a logout and nav to login page
 */
const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        const unAuth = graphQLErrors.some(
          err => String(err.extensions.code).toLowerCase() === 'unauthenticated' ||
            String(err.message).toLowerCase() === 'unauthenticated') // neo4jGraphql sends the incorrect code with a message for unauth.
        if (unAuth) {
            const requestPath = window.location.pathname;

            if (UNAUTHENTICATED_PATHS.includes(requestPath)) { // ignore
                return;
            }

            console.log(`Apollo Client recieved UnAuth logging out user: ${localStorage.getItem('token')}`)
            localStorage.removeItem('token');
            client?.clearStore();

              Swal.fire({
                title: 'SSO login failed or Login Expired',
                text: 'We will redirect you to the login page.',
                icon: 'warning',
                backdrop: `rgba(60, 60, 60, 0.7)`,
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then(() => (window.location.href = '/login'));
        }
    }
});

// Middleware to Intercept Responses & update access token
const responseInterceptor = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        const context = operation.getContext();
        const newToken = context.response?.headers.get("X-Turbamedia-Token");

        if (newToken) {
            localStorage.setItem("token", newToken);
        }

        return response;
    });
});

const client = new ApolloClient({
    link: ApolloLink.from([ authLink, responseInterceptor, errorLink, httpLink ]),
    cache: new InMemoryCache()
})

export default client
