import React from 'react'
import { Table, Dropdown, Button } from 'react-bootstrap'
import { gql, useMutation } from '@apollo/client'
import { showNotification } from '../../utils/notifications'

const UPDATE_PAGE_SETTINGS = gql`
    mutation updatePageSettings($landingPageId: ID!, $pageSettings: String!) {
        updatePageSettings(landingPageId: $landingPageId, pageSettings: $pageSettings) {
            id
            title
            domain
            pageSettings
        }
    }
`

const HeaderTable = ({ headers, landingPages, type }) => {
    const [updateLandingPage] = useMutation(UPDATE_PAGE_SETTINGS, {
        onError() {
            showNotification('Something went wrong. Try again later.', 'error')
        },
        onCompleted: () => {
            showNotification('Header has been applied successfully!', 'success')
        },
    })

    const onApplyHeadings = async (index, header) => {
        const { pageHeading, pageSubHeading } = JSON.parse(landingPages.data.landingPages[index].pageSettings)
        const pageSettings = {
            pageHeading: type === 'pageHeading' ? header : pageHeading,
            pageSubHeading: type === 'pageSubHeading' ? header : pageSubHeading,
        }

        await updateLandingPage({
            variables: {
                landingPageId: landingPages.data.landingPages[index].id,
                pageSettings: JSON.stringify(pageSettings),
            },
        })
    }

    return (
        <div className="table-responsive" style={{height: '312px', overflow: 'auto'}}>
            <Table id="basic-table" className="table mb-0 table-striped" role="grid">
                <tbody>
                    {headers &&
                        headers.map((header, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <h6 className="mb-0">{index + 1}</h6>
                                    </div>
                                </td>
                                <td className="text-primary">{header}</td>
                                <td className="text-end">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            as={Button}
                                            variant="success"
                                            size="sm"
                                            type="button"
                                            id="dropdownMenuButton"
                                        >
                                            Apply
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            aria-labelledby="dropdownMenuButton"
                                            alignright="true"
                                            popperConfig={{
                                                modifiers: [
                                                    {
                                                        name: 'flip',
                                                        options: {
                                                            fallbackPlacements: ['top', 'left'],
                                                            padding: { bottom: 12 },
                                                        },
                                                    },
                                                ],
                                            }}
                                            className="reports-dropdown"
                                        >
                                            <li>
                                                <h6 className="dropdown-header">Select Landing Page</h6>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            {landingPages.data.landingPages.map((page, pageIndex) => (
                                                <Dropdown.Item
                                                    key={page.id}
                                                    href="#"
                                                    onClick={() => onApplyHeadings(pageIndex, header)}
                                                >
                                                    {page.title}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    )
}

export default HeaderTable
